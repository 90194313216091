import React from "react"
import {ProtectedDialogRoute} from "../routes"

const RenderDialogItems = ({ routes, basePath }) => {

  const renderRoute = ({ component, history, match })  => {
    const Component = component
    const params = (match && match.params) ? {...match.params} : {}
    return <Component params={params} show={match != null} onHide = {() => { history.push(basePath)} } />
  }


  return <>{ Object.keys(routes).map(key => (
    <ProtectedDialogRoute key={key} path={routes[key].path}>
      {({ history, match }) => renderRoute({ component: routes[key].component, history, match })}
    </ProtectedDialogRoute>
  )) }</>
}


export default RenderDialogItems
