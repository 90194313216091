export const ASSESSMENT_LEVELS = "/api/pa/assessment-levels";
export const ASSESSMENT_LEVEL = "/api/pa/assessment-levels/:param";
export const ASSESSMENT_LEVEL_ACTIVATE =
  "/api/pa/assessment-levels/:param/activate";
export const EXPORT_ASSESSMENT_LEVEL = "/api/pa/assessment-levels/export";
export const IMPORT_ASSESSMENT_LEVEL = "/api/pa/assessment-levels/import";

// request endpoints
export const REQUEST_ASSESSMENT_LEVELS = "/api/pa/request-assessment-levels";
export const REQUEST_ASSESSMENT_LEVEL =
  "/api/pa/request-assessment-levels/:param";
export const REQUEST_ASSESSMENT_LEVEL_ACCEPT =
  "/api/pa/request-assessment-levels/:param/valid";
export const REQUEST_ASSESSMENT_LEVEL_REFUSE =
  "/api/pa/request-assessment-levels/:param/reject";
