// contractAgreement
export const FETCH_CONTRACT_AGREEMENTS_INIT = "contractAgreement@FETCH_CONTRACT_AGREEMENTS_INIT"
export const FETCH_CONTRACT_AGREEMENTS_SUCCEDED = "contractAgreement@FETCH_CONTRACT_AGREEMENTS_SUCCEDED"
export const FETCH_CONTRACT_AGREEMENTS_FAILED = "contractAgreement@FETCH_CONTRACT_AGREEMENTS_FAILED"

export const CLEAR_CONTRACT_AGREEMENT = "contractAgreement@CLEAR_CONTRACT_AGREEMENT"

export const CREATE_CONTRACT_AGREEMENT_INIT = "contractAgreement@CREATE_CONTRACT_AGREEMENT_INIT"
export const CREATE_CONTRACT_AGREEMENT_SUCCEDED = "contractAgreement@CREATE_CONTRACT_AGREEMENT_SUCCEDED"
export const CREATE_CONTRACT_AGREEMENT_FAILED = "contractAgreement@CREATE_CONTRACT_AGREEMENT_FAILED"

export const FETCH_DOCUMENT_INIT = "contractAgreement@FETCH_DOCUMENT_INIT"
export const FETCH_DOCUMENT_SUCCEDED = "contractAgreement@FETCH_DOCUMENT_SUCCEDED"
export const FETCH_DOCUMENT_FAILED = "contractAgreement@FETCH_DOCUMENT_FAILED"

export const FETCH_CONTRACT_AGREEMENT_INIT = "contractAgreement@FETCH_CONTRACT_AGREEMENT_INIT"
export const FETCH_CONTRACT_AGREEMENT_SUCCEDED = "contractAgreement@FETCH_CONTRACT_AGREEMENT_SUCCEDED"
export const FETCH_CONTRACT_AGREEMENT_FAILED = "contractAgreement@FETCH_CONTRACT_AGREEMENT_FAILED"

export const EDIT_CONTRACT_AGREEMENT_INIT = "contractAgreement@EDIT_CONTRACT_AGREEMENT_INIT"
export const EDIT_CONTRACT_AGREEMENT_SUCCEDED = "contractAgreement@EDIT_CONTRACT_AGREEMENT_SUCCEDED"
export const EDIT_CONTRACT_AGREEMENT_FAILED = "contractAgreement@EDIT_CONTRACT_AGREEMENT_FAILED"

export const ENABLE_CONTRACT_AGREEMENT_INIT = "contractAgreement@ENABLE_CONTRACT_AGREEMENT_INIT"
export const ENABLE_CONTRACT_AGREEMENT_SUCCEDED = "contractAgreement@ENABLE_CONTRACT_AGREEMENT_SUCCEDED"
export const ENABLE_CONTRACT_AGREEMENT_FAILED = "contractAgreement@ENABLE_CONTRACT_AGREEMENT_FAILED"

export const DISABLE_CONTRACT_AGREEMENT_INIT = "contractAgreement@DISABLE_CONTRACT_AGREEMENT_INIT"
export const DISABLE_CONTRACT_AGREEMENT_SUCCEDED = "contractAgreement@DISABLE_CONTRACT_AGREEMENT_SUCCEDED"
export const DISABLE_CONTRACT_AGREEMENT_FAILED = "contractAgreement@DISABLE_CONTRACT_AGREEMENT_FAILED"

export const ENABLE_CONTRACT_AGREEMENTS_INIT = "contractAgreement@ENABLE_CONTRACT_AGREEMENTS_INIT"
export const ENABLE_CONTRACT_AGREEMENTS_SUCCEDED = "contractAgreement@ENABLE_CONTRACT_AGREEMENTS_SUCCEDED"
export const ENABLE_CONTRACT_AGREEMENTS_FAILED = "contractAgreement@ENABLE_CONTRACT_AGREEMENTS_FAILED"

export const DISABLE_CONTRACT_AGREEMENTS_INIT = "contractAgreement@DISABLE_CONTRACT_AGREEMENTS_INIT"
export const DISABLE_CONTRACT_AGREEMENTS_SUCCEDED = "contractAgreement@DISABLE_CONTRACT_AGREEMENTS_SUCCEDED"
export const DISABLE_CONTRACT_AGREEMENTS_FAILED = "contractAgreement@DISABLE_CONTRACT_AGREEMENTS_FAILED"
