// role
export const FETCH_ROLES_INIT = "role@FETCH_ROLES_INIT"
export const FETCH_ROLES_SUCCEDED = "role@FETCH_ROLES_SUCCEDED"
export const FETCH_ROLES_FAILED = "role@FETCH_ROLES_FAILED"

export const CLEAR_ROLE = "role@CLEAR_ROLE"

export const CREATE_ROLE_INIT = "role@CREATE_ROLE_INIT"
export const CREATE_ROLE_SUCCEDED = "role@CREATE_ROLE_SUCCEDED"
export const CREATE_ROLE_FAILED = "role@CREATE_ROLE_FAILED"

export const FETCH_ROLE_INIT = "role@FETCH_ROLE_INIT"
export const FETCH_ROLE_SUCCEDED = "role@FETCH_ROLE_SUCCEDED"
export const FETCH_ROLE_FAILED = "role@FETCH_ROLE_FAILED"

export const EDIT_ROLE_INIT = "role@EDIT_ROLE_INIT"
export const EDIT_ROLE_SUCCEDED = "role@EDIT_ROLE_SUCCEDED"
export const EDIT_ROLE_FAILED = "role@EDIT_ROLE_FAILED"

export const ENABLE_ROLE_INIT = "role@ENABLE_ROLE_INIT"
export const ENABLE_ROLE_SUCCEDED = "role@ENABLE_ROLE_SUCCEDED"
export const ENABLE_ROLE_FAILED = "role@ENABLE_ROLE_FAILED"

export const DISABLE_ROLE_INIT = "role@DISABLE_ROLE_INIT"
export const DISABLE_ROLE_SUCCEDED = "role@DISABLE_ROLE_SUCCEDED"
export const DISABLE_ROLE_FAILED = "role@DISABLE_ROLE_FAILED"

export const ENABLE_ROLES_INIT = "role@ENABLE_ROLES_INIT"
export const ENABLE_ROLES_SUCCEDED = "role@ENABLE_ROLES_SUCCEDED"
export const ENABLE_ROLES_FAILED = "role@ENABLE_ROLES_FAILED"

export const DISABLE_ROLES_INIT = "role@DISABLE_ROLES_INIT"
export const DISABLE_ROLES_SUCCEDED = "role@DISABLE_ROLES_SUCCEDED"
export const DISABLE_ROLES_FAILED = "role@DISABLE_ROLES_FAILED"
