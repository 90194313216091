import { ACTIONS } from "./../constants"


const initialState = { 
  smsSkeletons: [], 
  smsSkeleton: {}, 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: false,
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_SMS_SKELETON : {
      return { ...state, success: false, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_SMS_SKELETONS_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_SMS_SKELETONS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, smsSkeletons: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_SMS_SKELETONS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_SMS_SKELETON_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.CREATE_SMS_SKELETON_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_SMS_SKELETON_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_SMS_SKELETON_INIT : {      
      return { ...state, isLoading: true, error: null, success:false }
    }
    case ACTIONS.EDIT_SMS_SKELETON_SUCCEDED : {
      return { ...state, smsSkeleton: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_SMS_SKELETON_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }
 
    case ACTIONS.FETCH_SMS_SKELETON_INIT : {
      return { ...state, isLoading: true, smsSkeleton: null, error: null }
    }
    case ACTIONS.FETCH_SMS_SKELETON_SUCCEDED : {
      return { ...state, smsSkeleton: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_SMS_SKELETON_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_SMS_SKELETON_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_SMS_SKELETON_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_SMS_SKELETON_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_SMS_SKELETON_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_SMS_SKELETON_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_SMS_SKELETON_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_SMS_SKELETONS_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_SMS_SKELETONS_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_SMS_SKELETONS_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_SMS_SKELETONS_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_SMS_SKELETONS_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_SMS_SKELETONS_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
