export const ASSESSMENT_TOOLS = "/api/pa/evaluation-tools";
export const ASSESSMENT_TOOL = "/api/pa/evaluation-tools/:param";

export const ASSESSMENT_TOOL_DEACTIVATE =
  "/api/pa/evaluation-tools/:param/deactivate";
export const ASSESSMENT_TOOL_ACTIVATE =
  "/api/pa/evaluation-tools/:param/activate";
export const EXPORT_ASSESSMENT_TOOLS = "/api/pa/evaluation-tools/export";
export const IMPORT_ASSESSMENT_TOOLS = "/api/pa/evaluation-tools/import";

export const REQUEST_ASSESSMENT_TOOLS = "/api/pa/request-evaluation-tools";
export const REQUEST_ASSESSMENT_TOOL =
  "/api/pa/request-evaluation-tools/:param";
export const REQUEST_ASSESSMENT_TOOL_ACCEPT =
  "/api/pa/request-evaluation-tools/:param/valid";
export const REQUEST_ASSESSMENT_TOOL_REFUSE =
  "/api/pa/request-evaluation-tools/:param/reject";
