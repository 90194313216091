import { ACTIONS } from "./../constants"


const initialState = { 
  structures: [], 
  structure: null, 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_STRUCTURE : {
      return { ...state, success: false, isFetching: false, isLoading: false, error: null }
    }

    case ACTIONS.FETCH_STRUCTURES_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_STRUCTURES_SUCCEDED : {
      const { count , results } = payload
      return { ...state, structures: results, totalSize: count, isFetching: false, success: false, error: null }
    }
    case ACTIONS.FETCH_STRUCTURES_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_STRUCTURE_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.CREATE_STRUCTURE_SUCCEDED : {
      return { ...state, structure: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_STRUCTURE_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_STRUCTURE_INIT : {
      return { ...state, isLoading: true, uccess: false, error: null }
    }
    case ACTIONS.EDIT_STRUCTURE_SUCCEDED : {
      return { ...state, structure: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_STRUCTURE_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }
 
    case ACTIONS.FETCH_STRUCTURE_INIT : {
      return { ...state, isLoading: true, uccess: false, structure: null, error: null }
    }
    case ACTIONS.FETCH_STRUCTURE_SUCCEDED : {
      return { ...state, structure: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_STRUCTURE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_STRUCTURE_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_STRUCTURE_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_STRUCTURE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_STRUCTURE_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_STRUCTURE_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_STRUCTURE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_STRUCTURES_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_STRUCTURES_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_STRUCTURES_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_STRUCTURES_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_STRUCTURES_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_STRUCTURES_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
