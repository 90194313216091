export const SKILLS = "/api/pa/skills";
export const SKILL = "/api/pa/skills/:param";
export const SKILL_DEACTIVATE = "/api/pa/skills/:param";
export const SKILL_ACTIVATE = "/api/pa/skills/:param/activate";
export const EXPORT_SKILLS = "/api/pa/skills/export";
export const IMPORT_SKILLS = "/api/pa/skills/import";

export const REQUEST_SKILLS = "/api/pa/request-skills";
export const REQUEST_SKILL = "/api/pa/request-skills/:param";
export const REQUEST_SKILL_ACCEPT = "/api/pa/request-skills/:param/valid";
export const REQUEST_SKILL_REFUSE = "/api/pa/request-skills/:param/reject";
