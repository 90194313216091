import { ACTIONS } from "./../constants"


const initialState = { 
  internalRules: null, 
  employmentContract: null,
  internalRulesFileName: null,
  employmentContractFileName: null,
  isFetching: false, 
  isLoading: false,
  success: false,
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_DOCUMENT : {
      return { ...state, success: false, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_DOCUMENT_INIT : {
      return { ...state, isFetching: true, employmentContract: null, internalRules: null, success: false, error: null }
    }
    case ACTIONS.FETCH_INTERNAL_RULES_DOCUMENT_SUCCEDED : {
      const matches = payload.headers["content-disposition"].match(/"(.*?)"/)
      return { 
        ...state, 
        internalRules: payload.data, 
        internalRulesFileName: matches.length ? matches[1]: "internal_rules", 
        isFetching: false, 
        error: null 
      }
    }
    case ACTIONS.FETCH_EMPLOYMENT_CONTRACT_DOCUMENT_SUCCEDED : {
      const matches = payload.headers["content-disposition"].match(/"(.*?)"/)
      return { 
        ...state, 
        employmentContract: payload.data, 
        employmentContractFileName: matches.length ? matches[1]: "employment_contract",
        isFetching: false, 
        error: null 
      }
    }
    case ACTIONS.FETCH_DOCUMENT_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.UPLOAD_DOCUMENT_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.UPLOAD_DOCUMENT_SUCCEDED : {
      return { ...state, success: true, employmentContract: null, internalRules: null, isLoading: false, error: null }
    }
    case ACTIONS.UPLOAD_DOCUMENT_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }
    default: {
      return state
    }
  }
}
