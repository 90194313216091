import { ACTIONS } from "../constants"


const initialState = { 
  serviceTypes: [], 
  serviceType: {}, 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: false,
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_SERVICE_TYPE : {
      return { ...state, success: false, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_SERVICE_TYPES_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_SERVICE_TYPES_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, serviceTypes: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_SERVICE_TYPES_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_SERVICE_TYPE_INIT : {
      return { ...state, isLoading: true, error: null, success: false }
    }
    case ACTIONS.CREATE_SERVICE_TYPE_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_SERVICE_TYPE_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_SERVICE_TYPE_INIT : {      
      return { ...state, isLoading: true, error: null, success:false }
    }
    case ACTIONS.EDIT_SERVICE_TYPE_SUCCEDED : {
      return { ...state, serviceType: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_SERVICE_TYPE_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }
 
    case ACTIONS.FETCH_SERVICE_TYPE_INIT : {
      return { ...state, isLoading: true, serviceType: null, error: null }
    }
    case ACTIONS.FETCH_SERVICE_TYPE_SUCCEDED : {
      return { ...state, serviceType: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_SERVICE_TYPE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_SERVICE_TYPE_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_SERVICE_TYPE_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_SERVICE_TYPE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_SERVICE_TYPES_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.DISABLE_SERVICE_TYPES_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_SERVICE_TYPES_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_SERVICE_TYPE_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_SERVICE_TYPE_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_SERVICE_TYPE_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_SERVICE_TYPES_INIT : {
      return { ...state, isLoading: true, success: false, error: null }
    }
    case ACTIONS.ENABLE_SERVICE_TYPES_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_SERVICE_TYPES_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
