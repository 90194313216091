export const CLEAR_DOCUMENT = "document@CLEAR_DOCUMENT"

export const UPLOAD_DOCUMENT_INIT = "document@CREATE_DOCUMENT_INIT"
export const UPLOAD_DOCUMENT_SUCCEDED = "document@CREATE_DOCUMENT_SUCCEDED"
export const UPLOAD_DOCUMENT_FAILED = "document@CREATE_DOCUMENT_FAILED"

export const FETCH_DOCUMENT_INIT = "document@FETCH_DOCUMENT_INIT"
export const FETCH_INTERNAL_RULES_DOCUMENT_SUCCEDED = "document@FETCH_INTERNAL_RULES_DOCUMENT_SUCCEDED"
export const FETCH_EMPLOYMENT_CONTRACT_DOCUMENT_SUCCEDED = "document@FETCH_EMPLOYMENT_CONTRACT_DOCUMENT_SUCCEDED"
export const FETCH_DOCUMENT_FAILED = "document@FETCH_DOCUMENT_FAILED"
