/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import profileReducer from "./containers/profile/store/reducers"
import structureReducer from "./containers/structure/store/reducers"
import smsSkeletonReducer from "./containers/sms-skeleton/store/reducers"
import emailSkeletonReducer from "./containers/email-skeleton/store/reducers"
import userReducer from "./containers/user/store/reducers"
import contractAgreementReducer from "./containers/contract-agreement/store/reducers"
import establishmentReducer from "./containers/establishment/store/reducers"
import partnerTypeReducer from "./containers/partner-type/store/reducers"
import serviceTypeReducer from "./containers/service-type/store/reducers"
import roleReducer from "./containers/role/store/reducers"
import documentReducer from "./containers/document/store/reducers"
import groupReducer from "./containers/group/store/reducers"
import evaluationRubricReducer from "./containers/evaluation-rubric/store/reducers"
import assessmentLevelsReducer from "./containers/assessment-level/store/reducers"
import assessmentToolReducer from "./containers/assessment-tool/store/reducers"
import knowledgeSkillsReducer from "./containers/knowledge-skill/store/reducers"
import aptitudeSkillsReducer from "./containers/aptitude-skill/store/reducers"
import skillsReducer from "./containers/skill/store/reducers"
import listReducer from "./containers/list/store/reducers";


/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  structure: structureReducer,
  smsSkeleton: smsSkeletonReducer,
  emailSkeleton: emailSkeletonReducer,
  user: userReducer,
  profile: profileReducer,
  contractAgreement: contractAgreementReducer,
  establishment: establishmentReducer,
  partnerType: partnerTypeReducer,
  serviceType: serviceTypeReducer,
  role: roleReducer,
  document: documentReducer,
  group: groupReducer,
  evaluationRubric: evaluationRubricReducer,
  assessmentLevel: assessmentLevelsReducer,
  knowledgeSkill: knowledgeSkillsReducer,
  aptitudeSkill: aptitudeSkillsReducer,
  skill: skillsReducer,
  assessmentTool : assessmentToolReducer,
  list: listReducer
})

