// smsSkeleton
export const FETCH_SMS_SKELETONS_INIT = "smsSkeleton@FETCH_SMS_SKELETONS_INIT"
export const FETCH_SMS_SKELETONS_SUCCEDED = "smsSkeleton@FETCH_SMS_SKELETONS_SUCCEDED"
export const FETCH_SMS_SKELETONS_FAILED = "smsSkeleton@FETCH_SMS_SKELETONS_FAILED"

export const CLEAR_SMS_SKELETON = "smsSkeleton@CLEAR_SMS_SKELETON"

export const CREATE_SMS_SKELETON_INIT = "smsSkeleton@CREATE_SMS_SKELETON_INIT"
export const CREATE_SMS_SKELETON_SUCCEDED = "smsSkeleton@CREATE_SMS_SKELETON_SUCCEDED"
export const CREATE_SMS_SKELETON_FAILED = "smsSkeleton@CREATE_SMS_SKELETON_FAILED"

export const FETCH_SMS_SKELETON_INIT = "smsSkeleton@FETCH_SMS_SKELETON_INIT"
export const FETCH_SMS_SKELETON_SUCCEDED = "smsSkeleton@FETCH_SMS_SKELETON_SUCCEDED"
export const FETCH_SMS_SKELETON_FAILED = "smsSkeleton@FETCH_SMS_SKELETON_FAILED"

export const EDIT_SMS_SKELETON_INIT = "smsSkeleton@EDIT_SMS_SKELETON_INIT"
export const EDIT_SMS_SKELETON_SUCCEDED = "smsSkeleton@EDIT_SMS_SKELETON_SUCCEDED"
export const EDIT_SMS_SKELETON_FAILED = "smsSkeleton@EDIT_SMS_SKELETON_FAILED"

export const DISABLE_SMS_SKELETON_INIT = "smsSkeleton@DISABLE_SMS_SKELETON_INIT"
export const DISABLE_SMS_SKELETON_SUCCEDED = "smsSkeleton@DISABLE_SMS_SKELETON_SUCCEDED"
export const DISABLE_SMS_SKELETON_FAILED = "smsSkeleton@DISABLE_SMS_SKELETON_FAILED"

export const ENABLE_SMS_SKELETON_INIT = "smsSkeleton@ENABLE_SMS_SKELETON_INIT"
export const ENABLE_SMS_SKELETON_SUCCEDED = "smsSkeleton@ENABLE_SMS_SKELETON_SUCCEDED"
export const ENABLE_SMS_SKELETON_FAILED = "smsSkeleton@ENABLE_SMS_SKELETON_FAILED"

export const DISABLE_SMS_SKELETONS_INIT = "smsSkeleton@DISABLE_SMS_SKELETONS_INIT"
export const DISABLE_SMS_SKELETONS_SUCCEDED = "smsSkeleton@DISABLE_SMS_SKELETONS_SUCCEDED"
export const DISABLE_SMS_SKELETONS_FAILED = "smsSkeleton@DISABLE_SMS_SKELETONS_FAILED"

export const ENABLE_SMS_SKELETONS_INIT = "smsSkeleton@ENABLE_SMS_SKELETONS_INIT"
export const ENABLE_SMS_SKELETONS_SUCCEDED = "smsSkeleton@ENABLE_SMS_SKELETONS_SUCCEDED"
export const ENABLE_SMS_SKELETONS_FAILED = "smsSkeleton@ENABLE_SMS_SKELETONS_FAILED"
