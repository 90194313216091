import { ACTIVATE, CREATE, UPDATE, DEACTIVATE, VIEW, DELETE, UNDELETE, VIEW_UPDATE } from "./Permissions"


export const CONTRACT_AGREEMENT = {
  module: "conventiontype",
  permissions: {
    [VIEW]: "view_conventiontype",
    [CREATE]: "add_conventiontype",
    [UPDATE]: "change_conventiontype",
    [ACTIVATE]: "activate_conventiontype",
    [DEACTIVATE]: "delete_conventiontype",
  }
}
export const EMAIL_SKELETON = {
  module: "mailtemplate",
  permissions: {
    [VIEW]: "view_mailtemplate",
    [CREATE]: "add_mailtemplate",
    [UPDATE]: "change_mailtemplate",
    [ACTIVATE]: "activate_mailtemplate",
    [DEACTIVATE]: "delete_mailtemplate",
  }
}
export const PARTNER_TYPE = {
  module: "partnertype",
  permissions: {
    [VIEW]: "view_partnertype",
    [CREATE]: "add_partnertype",
    [UPDATE]: "change_partnertype",
    [ACTIVATE]: "activate_partnertype",
    [DEACTIVATE]: "delete_partnertype",
  }
}
export const ESTABLISHMENT = {
  module: "school",
  permissions: {
    [VIEW]: "view_school",
    [CREATE]: "add_school",
    [UPDATE]: "change_school",
    [ACTIVATE]: "activate_school",
    [DEACTIVATE]: "delete_school",
  }
}
export const SERVICE_TYPE = {
  module: "servicetype",
  permissions: {
    [VIEW]: "view_servicetype",
    [CREATE]: "add_servicetype",
    [UPDATE]: "change_servicetype",
    [ACTIVATE]: "activate_servicetype",
    [DEACTIVATE]: "delete_servicetype",
  }
}
export const SMS_SKELETON = {
  module: "smstemplate",
  permissions: {
    [VIEW]: "view_smstemplate",
    [CREATE]: "add_smstemplate",
    [UPDATE]: "change_smstemplate",
    [ACTIVATE]: "activate_smstemplate",
    [DEACTIVATE]: "delete_smstemplate",
  }
}
export const STRUCTURE = {
  module: "structure",
  permissions: {
    [VIEW]: "view_structure",
    [CREATE]: "add_structure",
    [UPDATE]: "change_structure",
    [ACTIVATE]: "activate_structure",
    [DEACTIVATE]: "delete_structure",
  }
}
export const USER = {
  module: "profile",
  permissions: {
    [VIEW]: "view_profile",
    [CREATE]: "add_profile",
    [UPDATE]: "change_profile",
    [ACTIVATE]: "activate_profile",
    [DEACTIVATE]: "delete_profile",
    [DELETE]: "soft_delete_profile",
    [UNDELETE]: "undelete_profile",
  },
}
export const ROLE = {
  module: "role",
  permissions: {
    [VIEW]: "view_role",
    [CREATE]: "add_role",
    [UPDATE]: "change_role",
    [ACTIVATE]: "activate_role",
    [DEACTIVATE]: "delete_role",
  }
}
export const GROUP = {
  module: "group",
  permissions: {
    [VIEW]: "view_group",
    [CREATE]: "add_group",
    [UPDATE]: "change_group",
    [DEACTIVATE]: "delete_group",
    [ACTIVATE]: "activate_group",
  }
}
export const DOCUMENT = {
  module: "document",
  permissions: {
    [VIEW_UPDATE]: "view_update_document",
  }
}


export const EVALUATION_RUBRIC = {
  module: "evaluation_rubric",
  permissions: {
    [VIEW]: "view_evaluationrubric",
    [CREATE]: "add_evaluationrubric",
    [UPDATE]: "change_evaluationrubric",
    [ACTIVATE]: "activate_evaluationrubric",
    [DEACTIVATE]: "delete_evaluationrubric",
  },
}

export const ASSESSMENT_LEVEL = {
  module: "assessment_level",
  permissions: {
    [VIEW]: "view_assessmentlevel",
    [CREATE]: "add_assessmentlevel",
    [UPDATE]: "change_assessmentlevel",
    [ACTIVATE]: "activate_assessmentlevel",
    [DEACTIVATE]: "delete_assessmentlevel",
  },
}

export const KNOWLEDGE_SKILL = {
  module: "knowledge_skill",
  permissions: {
    [VIEW]: "view_knowledgeskill",
    [CREATE]: "add_knowledgeskill",
    [UPDATE]: "change_knowledgeskill",
    [ACTIVATE]: "activate_knowledgeskill",
    [DEACTIVATE]: "delete_knowledgeskill",
  },
}

export const APTITUDE_SKILL = {
  module: "aptitude_skill",
  permissions: {
    [VIEW]: "view_aptitudeskill",
    [CREATE]: "add_aptitudeskill",
    [UPDATE]: "change_aptitudeskill",
    [ACTIVATE]: "activate_aptitudeskill",
    [DEACTIVATE]: "delete_aptitudeskill",
  },
}

export const SKILL = {
  module: "skill",
  permissions: {
    [VIEW]: "view_kill",
    [CREATE]: "add_skill",
    [UPDATE]: "change_skill",
    [ACTIVATE]: "activate_skill",
    [DEACTIVATE]: "delete_skill",
  },
}


export const ASSESSMENT_TOOL = {
  module: "evaluation_tool",
  permissions: {
    [VIEW]: "view_evaluationtool",
    [CREATE]: "add_evaluationtool",
    [UPDATE]: "change_evaluationtool",
    [ACTIVATE]: "activate_evaluationtool",
    [DEACTIVATE]: "delete_evaluationtool",
  },
}

export const LIST = {
  module: "list",
  permissions: {
    [VIEW]: "view_list",
    [CREATE]: "add_list",
    [UPDATE]: "change_list",
    [ACTIVATE]: "activate_list",
    [DEACTIVATE]: "delete_list",
  },
};