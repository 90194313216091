// assessmentLevel
export const FETCH_ASSESSMENT_LEVELS_INIT =
  "assessmentLevel@FETCH_ASSESSMENT_LEVELS_INIT";
export const FETCH_ASSESSMENT_LEVELS_SUCCEDED =
  "assessmentLevel@FETCH_ASSESSMENT_LEVELS_SUCCEDED";
export const FETCH_ASSESSMENT_LEVELS_FAILED =
  "assessmentLevel@FETCH_ASSESSMENT_LEVELS_FAILED";

export const CLEAR_ASSESSMENT_LEVEL = "assessmentLevel@CLEAR_ASSESSMENT_LEVEL";

export const CREATE_ASSESSMENT_LEVEL_INIT =
  "assessmentLevel@CREATE_ASSESSMENT_LEVEL_INIT";
export const CREATE_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentLevel@CREATE_ASSESSMENT_LEVEL_SUCCEDED";
export const CREATE_ASSESSMENT_LEVEL_FAILED =
  "assessmentLevel@CREATE_ASSESSMENT_LEVEL_FAILED";

export const FETCH_ASSESSMENT_LEVEL_INIT =
  "assessmentLevel@FETCH_ASSESSMENT_LEVEL_INIT";
export const FETCH_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentLevel@FETCH_ASSESSMENT_LEVEL_SUCCEDED";
export const FETCH_ASSESSMENT_LEVEL_FAILED =
  "assessmentLevel@FETCH_ASSESSMENT_LEVEL_FAILED";

export const EDIT_ASSESSMENT_LEVEL_INIT =
  "assessmentLevel@EDIT_ASSESSMENT_LEVEL_INIT";
export const EDIT_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentLevel@EDIT_ASSESSMENT_LEVEL_SUCCEDED";
export const EDIT_ASSESSMENT_LEVEL_FAILED =
  "assessmentLevel@EDIT_ASSESSMENT_LEVEL_FAILED";

export const DEACTIVATE_ASSESSMENT_LEVEL_INIT =
  "assessmentLevel@DEACTIVATE_ASSESSMENT_LEVEL_INIT";
export const DEACTIVATE_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentLevel@DEACTIVATE_ASSESSMENT_LEVEL_SUCCEDED";
export const DEACTIVATE_ASSESSMENT_LEVEL_FAILED =
  "assessmentLevel@DEACTIVATE_ASSESSMENT_LEVEL_FAILED";

export const ACTIVATE_ASSESSMENT_LEVEL_INIT =
  "assessmentLevel@ACTIVATE_ASSESSMENT_LEVEL_INIT";
export const ACTIVATE_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentLevel@ACTIVATE_ASSESSMENT_LEVEL_SUCCEDED";
export const ACTIVATE_ASSESSMENT_LEVEL_FAILED =
  "assessmentLevel@ACTIVATE_ASSESSMENT_LEVEL_FAILED";

// // request asssessmentlevel
export const FETCH_REQUEST_ASSESSMENT_LEVELS_INIT =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVELS_INIT";
export const FETCH_REQUEST_ASSESSMENT_LEVELS_SUCCEDED =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVELS_SUCCEDED";
export const FETCH_REQUEST_ASSESSMENT_LEVELS_FAILED =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVELS_FAILED";

export const CREATE_REQUEST_ASSESSMENT_LEVEL_INIT =
  "requestAssessmentLevel@CREATE_REQUEST_ASSESSMENT_LEVEL_INIT";
export const CREATE_REQUEST_ASSESSMENT_LEVEL_SUCCEDED =
  "requestAssessmentLevel@CREATE_REQUEST_ASSESSMENT_LEVEL_SUCCEDED";
export const CREATE_REQUEST_ASSESSMENT_LEVEL_FAILED =
  "requestAssessmentLevel@CREATE_REQUEST_ASSESSMENT_LEVEL_FAILED";

export const FETCH_REQUEST_ASSESSMENT_LEVEL_INIT =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVEL_INIT";
export const FETCH_REQUEST_ASSESSMENT_LEVEL_SUCCEDED =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVEL_SUCCEDED";
export const FETCH_REQUEST_ASSESSMENT_LEVEL_FAILED =
  "requestAssessmentLevel@FETCH_REQUEST_ASSESSMENT_LEVEL_FAILED";

export const EDIT_REQUEST_ASSESSMENT_LEVEL_INIT =
  "requestAssessmentLevel@EDIT_REQUEST_ASSESSMENT_LEVEL_INIT";
export const EDIT_REQUEST_ASSESSMENT_LEVEL_SUCCEDED =
  "requestAssessmentLevel@EDIT_REQUEST_ASSESSMENT_LEVEL_SUCCEDED";
export const EDIT_REQUEST_ASSESSMENT_LEVEL_FAILED =
  "arequestAssessmentLevel@EDIT_REQUEST_ASSESSMENT_LEVEL_FAILED";

export const ACCEPT_ASSESSMENT_LEVEL_REQUEST_INIT =
  "requestAssessmentLevel@ACCEPT_ASSESSMENT_LEVEL_REQUEST_INIT";
export const ACCEPT_ASSESSMENT_LEVEL_REQUEST_SUCCEDED =
  "requestAssessmentLevel@ACCEPT_ASSESSMENT_LEVEL_REQUEST_SUCCEDED";
export const ACCEPT_ASSESSMENT_LEVEL_REQUEST_FAILED =
  "requestAssessmentLevel@ACCEPT_ASSESSMENT_LEVEL_REQUEST_FAILED";

export const REFUSE_ASSESSMENT_LEVEL_REQUEST_INIT =
  "requestAssessmentLevel@REFUSE_ASSESSMENT_LEVEL_REQUEST_INIT";
export const REFUSE_ASSESSMENT_LEVEL_REQUEST_SUCCEDED =
  "requestAssessmentLevel@REFUSE_ASSESSMENT_LEVEL_REQUEST_SUCCEDED";
export const REFUSE_ASSESSMENT_LEVEL_REQUEST_FAILED =
  "requestAssessmentLevel@REFUSE_ASSESSMENT_LEVEL_REQUEST_FAILED";
