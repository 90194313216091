export const ERROR_CODES = {
  404: "ERROR.CODE.NOT_FOUND",
  1100: "ERROR.CODE.BAD_CREDENTIALS",
  1101: "ERROR.CODE.INVALID_TOKEN",
  1102: "ERROR.CODE.BAD_TOKEN",
  1103: "ERROR.CODE.RESET_PASSWORD_FAILED",
  1104: "ERROR.CODE.USER_ALREADY_EXIST",
  1200: "ERROR.CODE.WRONG_PASSWORD",
  1302: "ERROR.CODE.APPOINTMENT_TIME_IS_NOT_VALID"
}

export const VALIDATION_ERROR_CODES = {
  required: "ERROR.CODE.VALIDATION.REQUIRED",
  unique: "ERROR.CODE.VALIDATION.UNIQUE",
  invalid: "ERROR.CODE.VALIDATION.INVALID",
  "null": "ERROR.CODE.VALIDATION.NULL",
  blank: "ERROR.CODE.VALIDATION.BLANK",
  max_length: "ERROR.CODE.VALIDATION.MAX_LENGTH",
  min_length: "ERROR.CODE.VALIDATION.MIN_LENGTH",
  invalid_choice: "ERROR.CODE.VALIDATION.INVALID_CHOICE",
  max_string_length: "ERROR.CODE.VALIDATION.MAX_STRING_LENGTH",
  min_value: "ERROR.CODE.VALIDATION.MIN_VALUE",
  max_value: "ERROR.CODE.VALIDATION.MAX_VALUE",
  max_whole_digits: "ERROR.CODE.VALIDATION.MAX_WHOLE_DIGITS",
  max_digits: "ERROR.CODE.VALIDATION.MAX_DIGITS",
  max_decimal_places: "ERROR.CODE.VALIDATION.MAX_DECIMAL_PLACES",
  not_a_list: "ERROR.CODE.VALIDATION.NOT_A_LIST",
  empty: "ERROR.CODE.VALIDATION.EMPTY",
  no_name: "ERROR.CODE.VALIDATION.NO_NAME",
  invalid_image: "ERROR.CODE.VALIDATION.INVALID_IMAGE",
  not_a_dict: "ERROR.CODE.VALIDATION.NOT_A_DICT",
  does_not_exist: "ERROR.CODE.VALIDATION.DOES_NOT_EXIST",
  incorrect_type: "ERROR.CODE.VALIDATION.INCORRECT_TYPE",
  incorrect_match: "ERROR.CODE.VALIDATION.INCORRECT_MATCH",
  no_match: "ERROR.CODE.VALIDATION.NO_MATCH"
}
