import { ACTIONS } from "./../constants";

const initialState = {
  evaluationRubrics: [],
  evaluationRubric: {},
  requestEvaluationRubrics: [],
  requestEvaluationRubric: {},
  evaluationRubricTotalSize: 0,
  requestEvaluationRubricTotalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isAccepted: false,
    isRefused: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_EVALUATION_RUBRIC: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_EVALUATION_RUBRICS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_EVALUATION_RUBRICS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        evaluationRubricTotalSize: count,
        evaluationRubrics: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_EVALUATION_RUBRICS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CREATE_EVALUATION_RUBRIC_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_EVALUATION_RUBRIC_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_EVALUATION_RUBRIC_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.EDIT_EVALUATION_RUBRIC_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_EVALUATION_RUBRIC_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_EVALUATION_RUBRIC_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_EVALUATION_RUBRIC_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        evaluationRubric: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_EVALUATION_RUBRIC_SUCCEDED: {
      return {
        ...state,
        evaluationRubric: payload,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_EVALUATION_RUBRIC_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.DEACTIVATE_EVALUATION_RUBRIC_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        error: null,
      };
    }
    case ACTIONS.DEACTIVATE_EVALUATION_RUBRIC_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isDeactivated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.DEACTIVATE_EVALUATION_RUBRIC_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.ACTIVATE_EVALUATION_RUBRIC_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        error: null,
      };
    }
    case ACTIONS.ACTIVATE_EVALUATION_RUBRIC_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isActivated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.ACTIVATE_EVALUATION_RUBRIC_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    // request evaluation rubric
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRICS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRICS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        requestEvaluationRubricTotalSize: count,
        requestEvaluationRubrics: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRICS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.ACCEPT_EVALUATION_RUBRIC_REQUEST_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.ACCEPT_EVALUATION_RUBRIC_REQUEST_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isAccepted: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.ACCEPT_EVALUATION_RUBRIC_REQUEST_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.REFUSE_EVALUATION_RUBRIC_REQUEST_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.REFUSE_EVALUATION_RUBRIC_REQUEST_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isRefused: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.REFUSE_EVALUATION_RUBRIC_REQUEST_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRIC_INIT: {
      return { ...state, isLoading: true, success: initialState.success, requestEvaluationRubric: null, error: null }
    }
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRIC_SUCCEDED: {
      return { ...state, requestEvaluationRubric: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_REQUEST_EVALUATION_RUBRIC_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }
    default: {
      return state;
    }
  }
};
