import { ACTIONS } from "./../constants"


const initialState = { 
  emailSkeletons: [], 
  emailSkeleton: null, 
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.FETCH_EMAIL_SKELETONS_INIT : {
      return { ...state, success: false, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_EMAIL_SKELETONS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, emailSkeletons: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_EMAIL_SKELETONS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_EMAIL_SKELETON_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.CREATE_EMAIL_SKELETON_SUCCEDED : {
      return { ...state, emailSkeleton: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_EMAIL_SKELETON_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_EMAIL_SKELETON_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.EDIT_EMAIL_SKELETON_SUCCEDED : {
      return { ...state, emailSkeleton: payload, success: true, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_EMAIL_SKELETON_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }
 
    case ACTIONS.FETCH_EMAIL_SKELETON_INIT : {
      return { ...state, isFetching: true, emailSkeleton: null, error: null }
    }
    case ACTIONS.FETCH_EMAIL_SKELETON_SUCCEDED : {
      return { ...state, emailSkeleton: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_EMAIL_SKELETON_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.DISABLE_EMAIL_SKELETON_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.DISABLE_EMAIL_SKELETON_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_EMAIL_SKELETON_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.ENABLE_EMAIL_SKELETON_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.ENABLE_EMAIL_SKELETON_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_EMAIL_SKELETON_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.ENABLE_EMAIL_SKELETONS_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.ENABLE_EMAIL_SKELETONS_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_EMAIL_SKELETONS_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }

    case ACTIONS.DISABLE_EMAIL_SKELETONS_INIT : {
      return { ...state, isLoading: true, error: null }
    }
    case ACTIONS.DISABLE_EMAIL_SKELETONS_SUCCEDED : {
      return { ...state, success: true, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_EMAIL_SKELETONS_FAILED : {
      return { ...state, isLoading: false, success: false, error: payload }
    }




    default: {
      return state
    }
  }
}
