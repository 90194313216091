import React from "react"
import _ from "lodash"
import {useParams, Switch, Redirect} from "react-router-dom"
import {ProtectedRoute} from "../routes"


const RenderNestedItems = ({ routes, defaultRoute, showOnly = false, editOnly = false, listPath, editPath, showPath }) => {

  const params = useParams()

  const onHide = (history) => {
    if (showOnly){
      history.push(showPath)
    }else if (editOnly){
      history.push(editPath)
    }
  }

  const renderRoute = ({ component, match, history })  => {
    const Component = component
    return <Component
      params={(match && !_.isEmpty(match.params)) ? {...match.params, ...params} : params}
      goBackToList={() => history.push(listPath)}
      history={history}
      showOnly={showOnly}
      editOnly={editOnly}
      editPath={editPath}
      showPath={showPath}
      goToShow={ editOnly && ((currentPath = "") => history.push(showPath + currentPath)) }
      goToEdit={ showOnly && ((currentPath = "") => history.push(editPath + currentPath)) }
      basePath={ showOnly ? showPath : editPath }
      onHide={() => onHide(history)}
    />
  }

  return <Switch>
    { Object.keys(routes).map((key) => (
      <ProtectedRoute key={key} path={routes[key].path.replace(":param", params.param)}>
        { ({ history, match }) => renderRoute({ component: routes[key].component, match, history }) }
      </ProtectedRoute>
    ))}
    <Redirect exact from={ showOnly ? showPath : editPath } to={routes[defaultRoute].path.replace(":param", params.param)} />
  </Switch>
}


export default RenderNestedItems
