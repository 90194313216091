// establishment
export const FETCH_ESTABLISHMENTS_INIT = "establishment@FETCH_ESTABLISHMENTS_INIT"
export const FETCH_ESTABLISHMENTS_SUCCEDED = "establishment@FETCH_ESTABLISHMENTS_SUCCEDED"
export const FETCH_ESTABLISHMENTS_FAILED = "establishment@FETCH_ESTABLISHMENTS_FAILED"

export const CLEAR_ESTABLISHMENT = "establishment@CLEAR_ESTABLISHMENT"

export const CREATE_ESTABLISHMENT_INIT = "establishment@CREATE_ESTABLISHMENT_INIT"
export const CREATE_ESTABLISHMENT_SUCCEDED = "establishment@CREATE_ESTABLISHMENT_SUCCEDED"
export const CREATE_ESTABLISHMENT_FAILED = "establishment@CREATE_ESTABLISHMENT_FAILED"

export const FETCH_ESTABLISHMENT_INIT = "establishment@FETCH_ESTABLISHMENT_INIT"
export const FETCH_ESTABLISHMENT_SUCCEDED = "establishment@FETCH_ESTABLISHMENT_SUCCEDED"
export const FETCH_ESTABLISHMENT_FAILED = "establishment@FETCH_ESTABLISHMENT_FAILED"

export const EDIT_ESTABLISHMENT_INIT = "establishment@EDIT_ESTABLISHMENT_INIT"
export const EDIT_ESTABLISHMENT_SUCCEDED = "establishment@EDIT_ESTABLISHMENT_SUCCEDED"
export const EDIT_ESTABLISHMENT_FAILED = "establishment@EDIT_ESTABLISHMENT_FAILED"

export const ENABLE_ESTABLISHMENT_INIT = "establishment@ENABLE_ESTABLISHMENT_INIT"
export const ENABLE_ESTABLISHMENT_SUCCEDED = "establishment@ENABLE_ESTABLISHMENT_SUCCEDED"
export const ENABLE_ESTABLISHMENT_FAILED = "establishment@ENABLE_ESTABLISHMENT_FAILED"

export const DISABLE_ESTABLISHMENT_INIT = "establishment@DISABLE_ESTABLISHMENT_INIT"
export const DISABLE_ESTABLISHMENT_SUCCEDED = "establishment@DISABLE_ESTABLISHMENT_SUCCEDED"
export const DISABLE_ESTABLISHMENT_FAILED = "establishment@DISABLE_ESTABLISHMENT_FAILED"

export const ENABLE_ESTABLISHMENTS_INIT = "establishment@ENABLE_ESTABLISHMENT_INIT"
export const ENABLE_ESTABLISHMENTS_SUCCEDED = "establishment@ENABLE_ESTABLISHMENT_SUCCEDED"
export const ENABLE_ESTABLISHMENTS_FAILED = "establishment@ENABLE_ESTABLISHMENT_FAILED"

export const DISABLE_ESTABLISHMENTS_INIT = "establishment@DISABLE_ESTABLISHMENTS_INIT"
export const DISABLE_ESTABLISHMENTS_SUCCEDED = "establishment@DISABLE_ESTABLISHMENTS_SUCCEDED"
export const DISABLE_ESTABLISHMENTS_FAILED = "establishment@DISABLE_ESTABLISHMENTS_FAILED"

