// skill
export const FETCH_SKILLS_INIT = "skill@FETCH_SKILLS_INIT";
export const FETCH_SKILLS_SUCCEDED = "skill@FETCH_SKILLS_SUCCEDED";
export const FETCH_SKILLS_FAILED = "skill@FETCH_SKILLS_FAILED";

export const CLEAR_SKILL = "skill@CLEAR_SKILL";

export const CREATE_SKILL_INIT = "skill@CREATE_SKILL_INIT";
export const CREATE_SKILL_SUCCEDED = "skill@CREATE_SKILL_SUCCEDED";
export const CREATE_SKILL_FAILED = "skill@CREATE_SKILL_FAILED";

export const FETCH_SKILL_INIT = "skill@FETCH_SKILL_INIT";
export const FETCH_SKILL_SUCCEDED = "skill@FETCH_SKILL_SUCCEDED";
export const FETCH_SKILL_FAILED = "skill@FETCH_SKILL_FAILED";

export const FETCH_EVALUATION_RUBRICS_INIT =
  "skill@FETCH_EVALUATION_RUBRICS_INIT";
export const FETCH_EVALUATION_RUBRICS_SUCCEDED =
  "skill@FETCH_EVALUATION_RUBRICS_SUCCEDED";
export const FETCH_EVALUATION_RUBRICS_FAILED =
  "skill@FETCH_EVALUATION_RUBRICS_FAILED";

export const FETCH_APTITUDE_SKILL_INIT = "skill@FETCH_APTITUDE_SKILL_INIT";
export const FETCH_APTITUDE_SKILL_SUCCEDED =
  "skill@FETCH_APTITUDE_SKILL_SUCCEDED";
export const FETCH_APTITUDE_SKILL_FAILED = "skill@FETCH_APTITUDE_SKILL_FAILED";

export const FETCH_KNOWLEDGE_SKILL_INIT = "skill@FETCH_KNOWLEDGE_SKILL_INIT";
export const FETCH_KNOWLEDGE_SKILL_SUCCEDED =
  "skill@FETCH_KNOWLEDGE_SKILL_SUCCEDED";
export const FETCH_KNOWLEDGE_SKILL_FAILED =
  "skill@FETCH_KNOWLEDGE_SKILL_FAILED";

export const EDIT_SKILL_INIT = "skill@EDIT_SKILL_INIT";
export const EDIT_SKILL_SUCCEDED = "skill@EDIT_SKILL_SUCCEDED";
export const EDIT_SKILL_FAILED = "skill@EDIT_SKILL_FAILED";

export const EDIT_EVALUATION_RUBRICS_INIT =
  "skill@EDIT_EVALUATION_RUBRICS_INIT";
export const EDIT_EVALUATION_RUBRICS_SUCCEDED =
  "skill@EDIT_EVALUATION_RUBRICS_SUCCEDED";
export const EDIT_EVALUATION_RUBRICS_FAILED =
  "skill@EDIT_EVALUATION_RUBRICS_FAILED";

export const EDIT_APTITUDE_SKILL_INIT = "skill@EDIT_APTITUDE_SKILL_INIT";
export const EDIT_APTITUDE_SKILL_SUCCEDED =
  "skill@EDIT_APTITUDE_SKILL_SUCCEDED";
export const EDIT_APTITUDE_SKILL_FAILED = "skill@EDIT_APTITUDE_SKILL_FAILED";

export const EDIT_KNOWLEDGE_SKILL_INIT = "skill@EDIT_KNOWLEDGE_SKILL_INIT";
export const EDIT_KNOWLEDGE_SKILL_SUCCEDED =
  "skill@EDIT_KNOWLEDGE_SKILL_SUCCEDED";
export const EDIT_KNOWLEDGE_SKILL_FAILED = "skill@EDIT_KNOWLEDGE_SKILL_FAILED";

export const ENABLE_SKILL_INIT = "skill@ENABLE_SKILL_INIT";
export const ENABLE_SKILL_SUCCEDED = "skill@ENABLE_SKILL_SUCCEDED";
export const ENABLE_SKILL_FAILED = "skill@ENABLE_SKILL_FAILED";

export const DISABLE_SKILL_INIT = "skill@DISABLE_SKILL_INIT";
export const DISABLE_SKILL_SUCCEDED = "skill@DISABLE_SKILL_SUCCEDED";
export const DISABLE_SKILL_FAILED = "skill@DISABLE_SKILL_FAILED";

// Request skill
export const FETCH_REQUEST_SKILLS_INIT =
  "requestSkill@FETCH_REQUEST_SKILLS_INIT";
export const FETCH_REQUEST_SKILLS_SUCCEDED =
  "requestSkill@FETCH_REQUEST_SKILLS_SUCCEDED";
export const FETCH_REQUEST_SKILLS_FAILED =
  "requestSkill@FETCH_REQUEST_SKILLS_FAILED";

export const ACCEPT_SKILL_REQUEST_INIT =
  "requestSkill@ACCEPT_SKILL_REQUEST_INIT";
export const ACCEPT_SKILL_REQUEST_SUCCEDED =
  "requestSkill@ACCEPT_SKILL_REQUEST_SUCCEDED";
export const ACCEPT_SKILL_REQUEST_FAILED =
  "requestSkill@ACCEPT_SKILL_REQUEST_FAILED";

export const REFUSE_SKILL_REQUEST_INIT =
  "requestSkill@REFUSE_SKILL_REQUEST_INIT";
export const REFUSE_SKILL_REQUEST_SUCCEDED =
  "requestSkill@REFUSE_SKILL_REQUEST_SUCCEDED";
export const REFUSE_SKILL_REQUEST_FAILED =
  "requestSkill@REFUSE_SKILL_REQUEST_FAILED";
export const FETCH_REQUEST_SKILL_INIT = "requestSkill@FETCH_REQUEST_SKILL_INIT"
export const FETCH_REQUEST_SKILL_SUCCEDED = "requestSkill@FETCH_REQUEST_SKILL_SUCCEDED"
export const FETCH_REQUEST_SKILL_FAILED = "requestSkill@FETCH_REQUEST_SKILL_FAILED"

export const FETCH_REQUEST_EVALUATION_RUBRICS_INIT = "requestSkill@FETCH_REQUEST_EVALUATION_RUBRICS_INIT"
export const FETCH_REQUEST_EVALUATION_RUBRICS_SUCCEDED = "requestSkill@FETCH_EREQUEST_VALUATION_RUBRICS_SUCCEDED"
export const FETCH_REQUEST_EVALUATION_RUBRICS_FAILED = "requestSkill@FETCH_REQUEST_EVALUATION_RUBRICS_FAILED"