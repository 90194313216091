/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// root reducer
import commonReducer from "./modules/common/rootReducer"
import paReducer from "./modules/admin/rootReducer"

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  common: commonReducer,
  pa: paReducer
})

