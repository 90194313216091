export const EVALUATION_RUBRICS = "/api/pa/evaluation-rubrics";
export const EVALUATION_RUBRIC = "/api/pa/evaluation-rubrics/:param";
export const EVALUATION_RUBRIC_ACTIVATE =
  "/api/pa/evaluation-rubrics/:param/activate";
export const REQUEST_EVALUATION_RUBRICS = "/api/pa/request-evaluation-rubrics";
export const REQUEST_EVALUATION_RUBRIC =
  "/api/pa/request-evaluation-rubrics/:param";
export const REQUEST_EVALUATION_RUBRIC_ACCEPT =
  "/api/pa/request-evaluation-rubrics/:param/valid";
export const REQUEST_EVALUATION_RUBRIC_REFUSE =
  "/api/pa/request-evaluation-rubrics/:param/reject";
