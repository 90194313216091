// structure
export const FETCH_STRUCTURES_INIT = "structure@FETCH_STRUCTURES_INIT"
export const FETCH_STRUCTURES_SUCCEDED = "structure@FETCH_STRUCTURES_SUCCEDED"
export const FETCH_STRUCTURES_FAILED = "structure@FETCH_STRUCTURES_FAILED"

export const CLEAR_STRUCTURE= "structure@CLEAR_STRUCTURE"

export const CREATE_STRUCTURE_INIT = "structure@CREATE_STRUCTURE_INIT"
export const CREATE_STRUCTURE_SUCCEDED = "structure@CREATE_STRUCTURE_SUCCEDED"
export const CREATE_STRUCTURE_FAILED = "structure@CREATE_STRUCTURE_FAILED"

export const FETCH_STRUCTURE_INIT = "structure@FETCH_STRUCTURE_INIT"
export const FETCH_STRUCTURE_SUCCEDED = "structure@FETCH_STRUCTURE_SUCCEDED"
export const FETCH_STRUCTURE_FAILED = "structure@FETCH_STRUCTURE_FAILED"

export const EDIT_STRUCTURE_INIT = "structure@EDIT_STRUCTURE_INIT"
export const EDIT_STRUCTURE_SUCCEDED = "structure@EDIT_STRUCTURE_SUCCEDED"
export const EDIT_STRUCTURE_FAILED = "structure@EDIT_STRUCTURE_FAILED"

export const DISABLE_STRUCTURE_INIT = "structure@DISABLE_STRUCTURE_INIT"
export const DISABLE_STRUCTURE_SUCCEDED = "structure@DISABLE_STRUCTURE_SUCCEDED"
export const DISABLE_STRUCTURE_FAILED = "structure@DISABLE_STRUCTURE_FAILED"

export const ENABLE_STRUCTURE_INIT = "structure@ENABLE_STRUCTURE_INIT"
export const ENABLE_STRUCTURE_SUCCEDED = "structure@ENABLE_STRUCTURE_SUCCEDED"
export const ENABLE_STRUCTURE_FAILED = "structure@ENABLE_STRUCTURE_FAILED"

export const DISABLE_STRUCTURES_INIT = "structure@DISABLE_STRUCTURES_INIT"
export const DISABLE_STRUCTURES_SUCCEDED = "structure@DISABLE_STRUCTURES_SUCCEDED"
export const DISABLE_STRUCTURES_FAILED = "structure@DISABLE_STRUCTURES_FAILED"

export const ENABLE_STRUCTURES_INIT = "structure@ENABLE_STRUCTURES_INIT"
export const ENABLE_STRUCTURES_SUCCEDED = "structure@ENABLE_STRUCTURES_SUCCEDED"
export const ENABLE_STRUCTURES_FAILED = "structure@ENABLE_STRUCTURES_FAILED"
