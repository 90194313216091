// partnerType
export const FETCH_PARTNER_TYPES_INIT = "partnerType@FETCH_PARTNER_TYPES_INIT"
export const FETCH_PARTNER_TYPES_SUCCEDED = "partnerType@FETCH_PARTNER_TYPES_SUCCEDED"
export const FETCH_PARTNER_TYPES_FAILED = "partnerType@FETCH_PARTNER_TYPES_FAILED"

export const CLEAR_PARTNER_TYPE = "partnerType@CLEAR_PARTNER_TYPE"

export const CREATE_PARTNER_TYPE_INIT = "partnerType@CREATE_PARTNER_TYPE_INIT"
export const CREATE_PARTNER_TYPE_SUCCEDED = "partnerType@CREATE_PARTNER_TYPE_SUCCEDED"
export const CREATE_PARTNER_TYPE_FAILED = "partnerType@CREATE_PARTNER_TYPE_FAILED"

export const FETCH_PARTNER_TYPE_INIT = "partnerType@FETCH_PARTNER_TYPE_INIT"
export const FETCH_PARTNER_TYPE_SUCCEDED = "partnerType@FETCH_PARTNER_TYPE_SUCCEDED"
export const FETCH_PARTNER_TYPE_FAILED = "partnerType@FETCH_PARTNER_TYPE_FAILED"

export const EDIT_PARTNER_TYPE_INIT = "partnerType@EDIT_PARTNER_TYPE_INIT"
export const EDIT_PARTNER_TYPE_SUCCEDED = "partnerType@EDIT_PARTNER_TYPE_SUCCEDED"
export const EDIT_PARTNER_TYPE_FAILED = "partnerType@EDIT_PARTNER_TYPE_FAILED"

export const DISABLE_PARTNER_TYPE_INIT = "partnerType@DISABLE_PARTNER_TYPE_INIT"
export const DISABLE_PARTNER_TYPE_SUCCEDED = "partnerType@DISABLE_PARTNER_TYPE_SUCCEDED"
export const DISABLE_PARTNER_TYPE_FAILED = "partnerType@DISABLE_PARTNER_TYPE_FAILED"

export const ENABLE_PARTNER_TYPE_INIT = "partnerType@ENABLE_PARTNER_TYPE_INIT"
export const ENABLE_PARTNER_TYPE_SUCCEDED = "partnerType@ENABLE_PARTNER_TYPE_SUCCEDED"
export const ENABLE_PARTNER_TYPE_FAILED = "partnerType@ENABLE_PARTNER_TYPE_FAILED"

export const DISABLE_PARTNER_TYPES_INIT = "partnerType@DISABLE_PARTNER_TYPES_INIT"
export const DISABLE_PARTNER_TYPES_SUCCEDED = "partnerType@DISABLE_PARTNER_TYPES_SUCCEDED"
export const DISABLE_PARTNER_TYPES_FAILED = "partnerType@DISABLE_PARTNER_TYPES_FAILED"

export const ENABLE_PARTNER_TYPES_INIT = "partnerType@ENABLE_PARTNER_TYPES_INIT"
export const ENABLE_PARTNER_TYPES_SUCCEDED = "partnerType@ENABLE_PARTNER_TYPES_SUCCEDED"
export const ENABLE_PARTNER_TYPES_FAILED = "partnerType@ENABLE_PARTNER_TYPES_FAILED"
