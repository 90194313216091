// assessmentTool
export const FETCH_ASSESSMENT_TOOLS_INIT =
  "assessmentTool@FETCH_ASSESSMENT_TOOLS_INIT";
export const FETCH_ASSESSMENT_TOOLS_SUCCEDED =
  "assessmentTool@FETCH_ASSESSMENT_TOOLS_SUCCEDED";
export const FETCH_ASSESSMENT_TOOLS_FAILED =
  "assessmentTool@FETCH_ASSESSMENT_TOOLS_FAILED";

export const CLEAR_ASSESSMENT_TOOL = "assessmentTool@CLEAR_ASSESSMENT_TOOL";

export const CREATE_ASSESSMENT_TOOL_INIT =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_INIT";
export const CREATE_ASSESSMENT_TOOL_SUCCEDED =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_SUCCEDED";
export const CREATE_ASSESSMENT_TOOL_FAILED =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_FAILED";

export const FETCH_ASSESSMENT_TOOL_INIT =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_INIT";
export const FETCH_ASSESSMENT_TOOL_SUCCEDED =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_SUCCEDED";
export const FETCH_ASSESSMENT_TOOL_FAILED =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_FAILED";

export const FETCH_ASSESSMENT_LEVEL_INIT =
  "assessmentTool@FETCH_ASSESSMENT_LEVEL_INIT";
export const FETCH_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentTool@FETCH_ASSESSMENT_LEVEL_SUCCEDED";
export const FETCH_ASSESSMENT_LEVEL_FAILED =
  "assessmentTool@FETCH_ASSESSMENT_LEVEL_FAILED";

export const FETCH_SKILL_INIT = "assessmentTool@FETCH_SKILL_INIT";
export const FETCH_SKILL_SUCCEDED = "assessmentTool@FETCH_SKILL_SUCCEDED";
export const FETCH_SKILL_FAILED = "assessmentTool@FETCH_SKILL_FAILED";

export const EDIT_ASSESSMENT_TOOL_INIT =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_INIT";
export const EDIT_ASSESSMENT_TOOL_SUCCEDED =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_SUCCEDED";
export const EDIT_ASSESSMENT_TOOL_FAILED =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_FAILED";

export const EDIT_SKILL_INIT = "assessmentTool@EDIT_SKILL_INIT";
export const EDIT_SKILL_SUCCEDED = "assessmentTool@EDIT_SKILL_SUCCEDED";
export const EDIT_SKILL_FAILED = "assessmentTool@EDIT_SKILL_FAILED";

export const EDIT_ASSESSMENT_LEVEL_INIT =
  "assessmentTool@EDIT_ASSESSMENT_LEVEL_INIT";
export const EDIT_ASSESSMENT_LEVEL_SUCCEDED =
  "assessmentTool@EDIT_ASSESSMENT_LEVEL_SUCCEDED";
export const EDIT_ASSESSMENT_LEVEL_FAILED =
  "assessmentTool@EDIT_ASSESSMENT_LEVEL_FAILED";

export const ENABLE_ASSESSMENT_TOOL_INIT =
  "assessmentTool@ENABLE_ASSESSMENT_TOOL_INIT";
export const ENABLE_ASSESSMENT_TOOL_SUCCEDED =
  "assessmentTool@ENABLE_ASSESSMENT_TOOL_SUCCEDED";
export const ENABLE_ASSESSMENT_TOOL_FAILED =
  "assessmentTool@ENABLE_ASSESSMENT_TOOL_FAILED";

export const DISABLE_ASSESSMENT_TOOL_INIT =
  "assessmentTool@DISABLE_ASSESSMENT_TOOL_INIT";
export const DISABLE_ASSESSMENT_TOOL_SUCCEDED =
  "assessmentTool@DISABLE_ASSESSMENT_TOOL_SUCCEDED";
export const DISABLE_ASSESSMENT_TOOL_FAILED =
  "assessmentTool@DISABLE_ASSESSMENT_TOOL_FAILED";

export const FETCH_APPOINTMENTS_INIT = "assessmentTool@FETCH_APPOINTMENTS_INIT";
export const FETCH_APPOINTMENTS_SUCCEDED =
  "assessmentTool@FETCH_APPOINTMENTS_SUCCEDED";
export const FETCH_APPOINTMENTS_FAILED =
  "assessmentTool@FETCH_APPOINTMENTS_FAILED";

export const CREATE_ASSESSMENT_TOOL_APPOINTMENT_INIT =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_APPOINTMENT_INIT";
export const CREATE_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED";
export const CREATE_ASSESSMENT_TOOL_APPOINTMENT_FAILED =
  "assessmentTool@CREATE_ASSESSMENT_TOOL_APPOINTMENT_FAILED";

export const FETCH_ASSESSMENT_TOOL_APPOINTMENT_INIT =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_APPOINTMENT_INIT";
export const FETCH_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED";
export const FETCH_ASSESSMENT_TOOL_APPOINTMENT_FAILED =
  "assessmentTool@FETCH_ASSESSMENT_TOOL_APPOINTMENT_FAILED";

export const EDIT_ASSESSMENT_TOOL_APPOINTMENT_INIT =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_APPOINTMENT_INIT";
export const EDIT_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_APPOINTMENT_SUCCEDED";
export const EDIT_ASSESSMENT_TOOL_APPOINTMENT_FAILED =
  "assessmentTool@EDIT_ASSESSMENT_TOOL_APPOINTMENT_FAILED";

// request assessmentTool
export const FETCH_REQUEST_ASSESSMENT_TOOLS_INIT =
  "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOLS_INIT";
export const FETCH_REQUEST_ASSESSMENT_TOOLS_SUCCEDED =
  "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOLS_SUCCEDED";
export const FETCH_REQUEST_ASSESSMENT_TOOLS_FAILED =
  "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOLS_FAILED";

export const ACCEPT_ASSESSMENT_TOOL_REQUEST_INIT =
  "requestAssessmentTool@ACCEPT_ASSESSMENT_TOOL_REQUEST_INIT";
export const ACCEPT_ASSESSMENT_TOOL_REQUEST_SUCCEDED =
  "requestAssessmentTool@ACCEPT_ASSESSMENT_TOOL_REQUEST_SUCCEDED";
export const ACCEPT_ASSESSMENT_TOOL_REQUEST_FAILED =
  "requestAssessmentTool@ACCEPT_ASSESSMENT_TOOL_REQUEST_FAILED";

export const REFUSE_ASSESSMENT_TOOL_REQUEST_INIT =
  "requestAssessmentTool@REFUSE_ASSESSMENT_TOOL_REQUEST_INIT";
export const REFUSE_ASSESSMENT_TOOL_REQUEST_SUCCEDED =
  "requestAssessmentTool@REFUSE_ASSESSMENT_TOOL_REQUEST_SUCCEDED";
export const REFUSE_ASSESSMENT_TOOL_REQUEST_FAILED =
  "requestAssessmentTool@REFUSE_ASSESSMENT_TOOL_REQUEST_FAILED";

export const FETCH_REQUEST_ASSESSMENT_TOOL_INIT = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_INIT"
export const FETCH_REQUEST_ASSESSMENT_TOOL_SUCCEDED = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_SUCCEDED"
export const FETCH_REQUEST_ASSESSMENT_TOOL_FAILED = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_FAILED"

export const FETCH_REQUEST_ASSESSMENT_LEVEL_INIT = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_INIT"
export const FETCH_REQUEST_ASSESSMENT_LEVEL_SUCCEDED = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_SUCCEDED"
export const FETCH_REQUEST_ASSESSMENT_LEVEL_FAILED = "requestAssessmentTool@FETCH_REQUEST_ASSESSMENT_TOOL_FAILED"

export const FETCH_REQUEST_SKILL_INIT = "requestAssessmentTool@FETCH_REQUEST_SKILL_INIT"
export const FETCH_REQUEST_SKILL_SUCCEDED = "requestAssessmentTool@FETCH_REQUEST_SKILL_SUCCEDED"
export const FETCH_REQUEST_SKILL_FAILED = "requestAssessmentTool@FETCH_REQUEST_SKILL_FAILED"
