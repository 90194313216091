// emailSkeleton
export const FETCH_EMAIL_SKELETONS_INIT = "emailSkeleton@FETCH_EMAIL_SKELETONS_INIT"
export const FETCH_EMAIL_SKELETONS_SUCCEDED = "emailSkeleton@FETCH_EMAIL_SKELETONS_SUCCEDED"
export const FETCH_EMAIL_SKELETONS_FAILED = "emailSkeleton@FETCH_EMAIL_SKELETONS_FAILED"

export const CLEAR_EMAIL_SKELETON_FORM = "emailSkeleton@CLEAR_EMAIL_SKELETON_FORM"

export const CREATE_EMAIL_SKELETON_INIT = "emailSkeleton@CREATE_EMAIL_SKELETON_INIT"
export const CREATE_EMAIL_SKELETON_SUCCEDED = "emailSkeleton@CREATE_EMAIL_SKELETON_SUCCEDED"
export const CREATE_EMAIL_SKELETON_FAILED = "emailSkeleton@CREATE_EMAIL_SKELETON_FAILED"

export const FETCH_EMAIL_SKELETON_INIT = "emailSkeleton@FETCH_EMAIL_SKELETON_INIT"
export const FETCH_EMAIL_SKELETON_SUCCEDED = "emailSkeleton@FETCH_EMAIL_SKELETON_SUCCEDED"
export const FETCH_EMAIL_SKELETON_FAILED = "emailSkeleton@FETCH_EMAIL_SKELETON_FAILED"

export const EDIT_EMAIL_SKELETON_INIT = "emailSkeleton@EDIT_EMAIL_SKELETON_INIT"
export const EDIT_EMAIL_SKELETON_SUCCEDED = "emailSkeleton@EDIT_EMAIL_SKELETON_SUCCEDED"
export const EDIT_EMAIL_SKELETON_FAILED = "emailSkeleton@EDIT_EMAIL_SKELETON_FAILED"

export const DISABLE_EMAIL_SKELETON_INIT = "emailSkeleton@DISABLE_EMAIL_SKELETON_INIT"
export const DISABLE_EMAIL_SKELETON_SUCCEDED = "emailSkeleton@DISABLE_EMAIL_SKELETON_SUCCEDED"
export const DISABLE_EMAIL_SKELETON_FAILED = "emailSkeleton@DISABLE_EMAIL_SKELETON_FAILED"

export const ENABLE_EMAIL_SKELETON_INIT = "emailSkeleton@ENABLE_EMAIL_SKELETON_INIT"
export const ENABLE_EMAIL_SKELETON_SUCCEDED = "emailSkeleton@ENABLE_EMAIL_SKELETON_SUCCEDED"
export const ENABLE_EMAIL_SKELETON_FAILED = "emailSkeleton@ENABLE_EMAIL_SKELETON_FAILED"

export const DISABLE_EMAIL_SKELETONS_INIT = "emailSkeleton@DISABLE_EMAIL_SKELETONS_INIT"
export const DISABLE_EMAIL_SKELETONS_SUCCEDED = "emailSkeleton@DISABLE_EMAIL_SKELETONS_SUCCEDED"
export const DISABLE_EMAIL_SKELETONS_FAILED = "emailSkeleton@DISABLE_EMAIL_SKELETONS_FAILED"

export const ENABLE_EMAIL_SKELETONS_INIT = "emailSkeleton@ENABLE_EMAIL_SKELETONS_INIT"
export const ENABLE_EMAIL_SKELETONS_SUCCEDED = "emailSkeleton@ENABLE_EMAIL_SKELETONS_SUCCEDED"
export const ENABLE_EMAIL_SKELETONS_FAILED = "emailSkeleton@ENABLE_EMAIL_SKELETONS_FAILED"

export const CLEAR_STORE = "emailSkeleton@CLEAR_STORE"
