// group
export const FETCH_GROUPS_INIT = "group@FETCH_GROUPS_INIT"
export const FETCH_GROUPS_SUCCEDED = "group@FETCH_GROUPS_SUCCEDED"
export const FETCH_GROUPS_FAILED = "group@FETCH_GROUPS_FAILED"

export const CLEAR_GROUP = "group@CLEAR_GROUP"

export const CREATE_GROUP_INIT = "group@CREATE_GROUP_INIT"
export const CREATE_GROUP_SUCCEDED = "group@CREATE_GROUP_SUCCEDED"
export const CREATE_GROUP_FAILED = "group@CREATE_GROUP_FAILED"

export const FETCH_GROUP_INIT = "group@FETCH_GROUP_INIT"
export const FETCH_GROUP_SUCCEDED = "group@FETCH_GROUP_SUCCEDED"
export const FETCH_GROUP_FAILED = "group@FETCH_GROUP_FAILED"

export const EDIT_GROUP_INIT = "group@EDIT_GROUP_INIT"
export const EDIT_GROUP_SUCCEDED = "group@EDIT_GROUP_SUCCEDED"
export const EDIT_GROUP_FAILED = "group@EDIT_GROUP_FAILED"

export const DELETE_GROUP_INIT = "group@DELETE_GROUP_INIT"
export const DELETE_GROUP_SUCCEDED = "group@DELETE_GROUP_SUCCEDED"
export const DELETE_GROUP_FAILED = "group@DELETE_GROUP_FAILED"
