// serviceType
export const FETCH_SERVICE_TYPES_INIT = "serviceType@FETCH_SERVICE_TYPES_INIT"
export const FETCH_SERVICE_TYPES_SUCCEDED = "serviceType@FETCH_SERVICE_TYPES_SUCCEDED"
export const FETCH_SERVICE_TYPES_FAILED = "serviceType@FETCH_SERVICE_TYPES_FAILED"

export const CLEAR_SERVICE_TYPE = "serviceType@CLEAR_SERVICE_TYPE"

export const CREATE_SERVICE_TYPE_INIT = "serviceType@CREATE_SERVICE_TYPE_INIT"
export const CREATE_SERVICE_TYPE_SUCCEDED = "serviceType@CREATE_SERVICE_TYPE_SUCCEDED"
export const CREATE_SERVICE_TYPE_FAILED = "serviceType@CREATE_SERVICE_TYPE_FAILED"

export const FETCH_SERVICE_TYPE_INIT = "serviceType@FETCH_SERVICE_TYPE_INIT"
export const FETCH_SERVICE_TYPE_SUCCEDED = "serviceType@FETCH_SERVICE_TYPE_SUCCEDED"
export const FETCH_SERVICE_TYPE_FAILED = "serviceType@FETCH_SERVICE_TYPE_FAILED"

export const EDIT_SERVICE_TYPE_INIT = "serviceType@EDIT_SERVICE_TYPE_INIT"
export const EDIT_SERVICE_TYPE_SUCCEDED = "serviceType@EDIT_SERVICE_TYPE_SUCCEDED"
export const EDIT_SERVICE_TYPE_FAILED = "serviceType@EDIT_SERVICE_TYPE_FAILED"

export const DISABLE_SERVICE_TYPE_INIT = "serviceType@DISABLE_SERVICE_TYPE_INIT"
export const DISABLE_SERVICE_TYPE_SUCCEDED = "serviceType@DISABLE_SERVICE_TYPE_SUCCEDED"
export const DISABLE_SERVICE_TYPE_FAILED = "serviceType@DISABLE_SERVICE_TYPE_FAILED"

export const ENABLE_SERVICE_TYPE_INIT = "serviceType@ENABLE_SERVICE_TYPE_INIT"
export const ENABLE_SERVICE_TYPE_SUCCEDED = "serviceType@ENABLE_SERVICE_TYPE_SUCCEDED"
export const ENABLE_SERVICE_TYPE_FAILED = "serviceType@ENABLE_SERVICE_TYPE_FAILED"

export const DISABLE_SERVICE_TYPES_INIT = "serviceType@DISABLE_SERVICE_TYPES_INIT"
export const DISABLE_SERVICE_TYPES_SUCCEDED = "serviceType@DISABLE_SERVICE_TYPES_SUCCEDED"
export const DISABLE_SERVICE_TYPES_FAILED = "serviceType@DISABLE_SERVICE_TYPES_FAILED"

export const ENABLE_SERVICE_TYPES_INIT = "serviceType@ENABLE_SERVICE_TYPES_INIT"
export const ENABLE_SERVICE_TYPES_SUCCEDED = "serviceType@ENABLE_SERVICE_TYPES_SUCCEDED"
export const ENABLE_SERVICE_TYPES_FAILED = "serviceType@ENABLE_SERVICE_TYPES_FAILED"
